import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/MyNotes-Webapp/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "my-ipfs-links"
    }}>{`My IPFS Links`}</h2>
    <p><a parentName="p" {...{
        "href": "http://t.bdaily.club/jy9et9"
      }}>{`Link:My IPFS Wiki`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ipfs.globalupload.io/QmNvy1jWPN5Xb3DxXTnakWvsHjZmtXnEf8dJrLXMHJuFMH"
      }}>{`File:Microsoft Remote Desktop Beta.zip`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ipfs.globalupload.io/ipfs/QmXWjpK8eQ3MzEswKeo86vVT8XcL62AabLgmLNPs6zBgQG"
      }}>{`File:Brave-Browser.dmg`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ipfs.globalupload.io/ipfs/QmYNiFdqX21LMT7oLtpM7WEA62ANvwbtYu6hby77tdAUzd"
      }}>{`File:Huawei ARM`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ipfs.globalupload.io/ipfs/QmZMYYtPWF2CgS8dBswgpoJezYHQWZNK2Pbwhdb1vaueY8"
      }}>{`File:clonezilla-live-20190707-disco-amd64.iso`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://gateway.pinata.cloud/ipns/awesome.ipfs.io/"
      }}>{`Link:awesome.ipfs.io`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ipfs.ink"
      }}>{`Link:Publish markdown essays with one-click.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://gateway.pinata.cloud/ipfs/QmSGQrmvHe6fFiWdV2H8YnhLaoX4qe5nYEmJfUFx6LdUYa/"
      }}>{`Link:IPFS TODOs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ipfs.globalupload.io/ipfs/QmQt8gJETfQmXQVsNkegRDhHER1KFMsvqahG4qFWnoBsHE"
      }}>{`File:Siderus Orion.zip`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://t.bdaily.club/"
      }}>{`Link:Dcentralized URL shortening service based on IPFS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://gateway.pinata.cloud/ipfs/QmWZ3u5S7RjFXKfW6dSZhj7CozcvpqJxm48RYMmKkWcmNQ/"
      }}>{`Link:PubSub Chat Demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      